:root {
  --grid-portion: 16px;
  --font-size-by-grid: 1.2rem;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-color: #EEE;
  background: white;
  font-size: 14px;
  overflow-y: hidden;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

*{
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Width */
::-webkit-scrollbar {
    width: var(--grid-portion);
}

/* Track */
::-webkit-scrollbar-track {
    background: #E3B9FC;
}
/* Handle  */
::-webkit-scrollbar-thumb {
    border-radius: 0px;
    border-right: 1px solid #b91921;
    border-right-width: calc(var(--grid-portion) / 1);
}


/* Buttons */
::-webkit-scrollbar-button:single-button {
    background-color: #b91921;
    display: block;
    border-style: solid;
    height: calc(var(--grid-portion) );
}
/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 calc(var(--grid-portion)/2) calc(var(--grid-portion)/2) calc(var(--grid-portion)/2);
    border-color: transparent transparent #E3B9FC transparent;
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: calc(var(--grid-portion)/2) calc(var(--grid-portion)/2) 0 calc(var(--grid-portion)/2);
    border-color: #E3B9FC transparent transparent transparent;
}


/* Width */
body::-webkit-scrollbar {
  width: var(--grid-portion);
}

/* Track */
body::-webkit-scrollbar-track {
  background: #c9c9c9;
  // box-shadow: inset 0 0 4px #214F61;
  // border-radius: 0px;
}
/* Handle  */
body::-webkit-scrollbar-thumb {
  // border-radius: 4px;
  // box-shadow: inset 4px 2px 12px 2px #214F61;
  border-right: 1px solid #9b9b9b;
  border-right-width: calc(var(--grid-portion) / 1);
  border-radius: 10px;
}

/* Buttons */ 
body::-webkit-scrollbar-button:single-button {
  display: none;
}
