$firsColor:#e21a23;
$firsColorInactive:#e26e74;
$hoverColor: #ba171f;
$borderColor: $firsColor;
$testColor: white;

.btn-ximdex { 
  color: $testColor; 
  background-color: $firsColor; 
  border-color: $borderColor; 
  display: inline;
  height: max-content;
  font-size: var(--font-size-by-grid) !important;
  font-weight: bolder !important;
  border-radius: 0;
} 
   
.btn-ximdex:hover,
.btn-ximdex:active, 
.btn-ximdex.active, 
.open .dropdown-toggle.btn-ximdex { 
  color: $testColor; 
  background-color: $hoverColor; 
  border-color: $firsColor; 
} 
.btn-ximdex:focus{
  box-shadow: 0 0 0 0.25rem rgba($firsColor , 0.25);
}
  
.btn-ximdex:active, 
.btn-ximdex.active, 
.open .dropdown-toggle.btn-ximdex { 
  background-image: none; 
} 
   
.btn-ximdex.disabled, 
.btn-ximdex[disabled], 
fieldset[disabled] .btn-ximdex, 
.btn-ximdex.disabled:hover, 
.btn-ximdex[disabled]:hover, 
fieldset[disabled] .btn-ximdex:hover, 
.btn-ximdex.disabled:focus, 
.btn-ximdex[disabled]:focus, 
fieldset[disabled] .btn-ximdex:focus, 
.btn-ximdex.disabled:active, 
.btn-ximdex[disabled]:active, 
fieldset[disabled] .btn-ximdex:active, 
.btn-ximdex.disabled.active, 
.btn-ximdex[disabled].active, 
fieldset[disabled] .btn-ximdex.active { 
  background-color: $firsColorInactive; 
  opacity: 1 !important;
  border-color: $firsColor; 
} 
   
.btn-ximdex .badge { 
  color: $firsColor; 
  background-color: $testColor; 
}