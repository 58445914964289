#image {
    width: 100%;
    // min-height: 500px;

}

.buttonDiv {
    margin: auto;
}

.footer-inicial {
    width: 100%;
    margin: 0;
    background: center no-repeat;
    background-size: 100% 100%;
    height: 270px;
    margin-top: 0 !important;
}

.homeTitle {
    color: #e21a23;
}

.link {

    color: #e21a23;
    text-decoration: none;

    &:hover {
        color: #DC3447;
    }
}