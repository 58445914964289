.container-image{
    width: 100% ;
    height: 100%;
    min-height: 800px;
    background: no-repeat;
    background-position: 150px 40px ;
}

#evaluation-body {
    background-color: white;
    border-radius: 0;
}

#init-buttonNextQuestion{
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    border: 0px;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        background-color: #b91921
    }
}

// #buttonCheckResult{
//     position: absolute;
//     right: 0;
//     bottom: 3rem;
//     width: 175px;
//     border-radius: 10px;
//     margin: 20px;
//     padding: 5px 10px;
//     background-color: rgba(#007e7b, 0.2);
//     color: #0A223D;
//     outline: none;
//     border: 0px;
//     font-size: 18px;
//     cursor: pointer;

//     &:hover {
//         background-color:rgba(#007e7b, 0.4);
//     }
// }

// #buttonGoDashboard{
//     width: 175px;
//     border-radius: 10px;
//     margin: 20px;
//     padding: 5px 10px;
//     background-color: rgba(#007e7b, 0.2);
//     color: #0A223D;
//     outline: none;
//     border: 0px;
//     font-size: 18px;
//     cursor: pointer;

//     &:hover {
//         background-color:rgba(#007e7b, 0.4);
//     }
// }

.iframe-activity{
    width: 100%;
    position: absolute;
    top: 39px;
    max-height: 480px;
}


.container-activity {
    width: 800px;
    position: absolute;
    top: 43px;
    left: 482px;

    & h1 {
        margin-left: 9px;
        position: absolute;
        top: -8px;
    }
}
