.modalContainer {
    display: block; 
    position: fixed; 
    z-index: 2000;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);

    & .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border-bottom: 1px solid lightgray;
        border-right: 1px solid lightgray;
        border-left: 1px solid lightgray;
        border-top: 10px solid #58abb7;
        width: 60%;
        position: relative;

        & .close {
            color: #aaaaaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
            width: fit-content;
            position:absolute;
            top: 0;
            right: 10px;
        }
    
        & .close:hover, & .close:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }
    }
}