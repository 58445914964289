.logout-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    // top: 130px;
    // left: 160px;
    align-items: center;
    // height: 100%;
}

.logout-info-box {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #fff;
}

.logout-info-box h2 {
    color: #06235b;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}