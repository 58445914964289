/*#progressDiv {
    height: 25px;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 30%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 1rem;

    & .progressMarks {
        display: flex;
        flex-direction: row;
    }
    & div {
        height: 100%;
        background-color: white;
        width: 100%;

        &.accomplished {
            background-color: red;
            width: 100%;
        }

        &.actual { 
            background-color: white;
            border-radius: 0 10px 10px 0;
        }
    }
    .label {
        position: absolute;
        top: 0;
        bottom: 0;
        transform: translate(100%,0);
        right: -5px;
    }
}*/

.progressBar {
    align-items: center;
    display: flex;
    flex-direction: column;
}

#progressDiv {
    height: 12px;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    background-color: white;
    border: 1px solid black;
    align-items: center;

    & div {
        height: 100%;
        width: 100%;

        &.accomplished {
            width: 100%;
            background-color: green;
        }

        &.actual { 
            border-radius: 0;
        }
    }
}