.App {
    position: absolute;
    top: 0;
    background-repeat: repeat;
}

.border-red {
    border: 10px solid #e21a23;
}

.title-mhe-blue {
    color: #06235b;
    font-weight: bold;
}

.title-mhe-red {
    color: #e21a23;
    font-weight: bold;
    font-size: 2rem;
}

.title-mhe-blue {
    color: #06235b;
    font-weight: bold;
    font-size: 2rem;
}

.subtitle-mhe-blue {
    color: #06235b;
    font-size: 1.75rem;
}

.subtitle-mhe-red {
    color: #e21a23;
    font-size: 1.75rem;
}

.bgmhe-red {
    background-color: #e21a23;
    color: white;
}
.bgmhe-blue {
    color: white;
    background-color: #06235b;
}

.x-button {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 2;
    border-radius: 0 !important;
}

.border-double-red, .border-double-mix  {
    border-color: #b91921 !important;
    border-style: solid !important;
    border-width: var(--grid-portion) ;
}

.border-double-mix {
    &::before {
        content: "";
        border: calc((var(--grid-portion) / 2) + 0.5px) solid #E3B9FC ;
        width: calc(100% + var(--grid-portion));
        height: calc(100% + var(--grid-portion) );
        position: absolute;
        top: calc(var(--grid-portion) / 2 * -1);
        right:  calc(var(--grid-portion) / 2 * -1);
    }
}


.wrapper-scroll {
    height: calc(100% + calc(var(--grid-portion) * 2));
    position: relative;
    overflow: auto;
    margin-top: calc(var(--grid-portion) * -1);
    margin-right: calc(var(--grid-portion) * -1);
    padding: 20px;
}
