.rightBox {
    font-size: var(--font-size-by-grid);
    & .title {
        color: blue;
        // font-size: calc(var(--font-size-by-grid));
    }
}

.init-rightBox, .rightBox {
    position:absolute; 
    top: 0;
    right: 0;

    .timer-eval {
        width: 170px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .timer-question {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .timer { 
        background-color: white;
        display: flex;
        flex-direction: column;
        font-size: 16px;
    
        & .title {
            padding-top: 0.5em;
            text-align: center;
            margin: 0;
            // padding-bottom: 7px;
        }
    
        & .time { 
            text-align: center;
            margin: 0;
            // margin-top: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            border-top: 1px solid rgb(212, 212, 212);
        }
    }
}