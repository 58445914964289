#videos {
    width: 100%;
    height: 100%;
}

.home-video {
    position: absolute;
    height: 355px;
    background-color: white;

}

.home-text {
    position: absolute;
}

.home-button {
    height: 40px;
    width: 200px;
    font-size: 18px;
}

.homeTitle {
    background-color: white;
}

.link {
    color: #e21a23;
    text-decoration: none;

    &:hover {
        color: #DC3447;
    }
}