.teacher-rightBox {
    position:absolute;
    top: 0;
    right: 0;

    .timer { 
        width: 175px;
        border-radius: 10px;
        opacity: 0.7;
        background-color: white;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        margin-right: 15px;
        font-size: 16px;
    
        & .title {
            padding-top: 0.5em;
            text-align: center;
            margin: 0;
            padding-bottom: 7px;
        }
    
        & .time { 
            text-align: center;
            margin: 0;
            margin-top: 5;
            padding-top: 7px;
            padding-bottom: 7px;
            border-top: 1px solid rgb(212, 212, 212);
        }

        & .timeout {
            color: red;
        }
    }

    
}

.container-image-teacher{
    position: absolute;
    top: 0;
    left:0;
    width: 100% ;
    // height: 100vh;
    // min-height: 800px;
    background: no-repeat;
}

.teacher-iframe-activity{
    position: absolute;
    // width: 100%;
    // top: 87px;
    // max-height: 480p x;
}

#buttonNextQuestion{
    width: 175px;
    border-radius: 10px;
    margin-top: 15px;
    padding: 5px 10px;
    background-color: rgba(#007e7b, 0.2);
    color: #0A223D;
    outline: none;
    border: 0px;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        background-color:rgba(#007e7b, 0.4);
    }
}

.teacher-container-activity {
    & h1 {
        position: absolute;
        width: max-content;
    }
    
    & h2 {
        position: absolute;
        width: max-content;
        // background-color: white;
    }
}
